@keyframes squareScaleAnimation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.035); // Adjust the scaling factor as needed
    }
}

.square {
    transform-origin: center;
    animation: squareScaleAnimation 2s ease-in-out infinite; // You can adjust the duration and timing here
}