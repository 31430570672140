body{
  overflow-x:hidden;
}
body.MuiDialog-root {
  padding-right: 0 !important;
}

.grid-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-item {
  border: 1px solid black;
}
html, body, #root, .grid-container {
  height: 100%;
  margin: 0;
  padding: 0;
}