.upload-button {
    border-style: dashed !important;
    // border-width: 4px !important;
    transition: all 3s;
}

.upload-button:hover {
    border-style: solid;
    // border-width: 4px;
}

.upload-button-hover {
    border-style: solid !important;
    // border-width: 4px;
}